<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5><b-icon-plus-circle /> Adicionar Item</h5>
        <b-form @submit.prevent="buscar">
          <div class="row">
            <div class="col-12 col-sm-5 pr-0">
              <b-form-group label="Data">
                <div class="row">
                  <div class="col-6 pr-0">
                    <b-input type="date" v-model="filtros.periodo.dt_i" />
                  </div>
                  <div class="col-6 pl-1">
                    <b-input type="date" v-model="filtros.periodo.dt_f" />
                  </div>
                </div>
              </b-form-group>
            </div>
            <div class="col-12 col-sm-7 pl-1">
              <b-form-group label="Médico / Fornecedor / Laboratório">
                <select-paciente
                  @paciente="selecionarPacienteFiltro"
                  showFidelizacoes
                  :showBotaoAdd="false"
                  :showBotaoEdit="false"
                  setNullOnEmpty
                  :selecionado="filtros.entidadeId"
                />
              </b-form-group>
            </div>

            <div class="col-12 col-sm-3 pr-1">
              <b-form-group label="Tipo">
                <b-select
                  :options="tipos"
                  v-model="filtros.tipo"
                  value-field="value"
                />
              </b-form-group>
            </div>
            <div class="col-12 col-sm-3 pl-0 pr-1">
              <b-form-group label="Cliente">
                <b-input v-model="filtros.cliente" />
              </b-form-group>
            </div>
            <div class="col-12 col-sm-3 pl-0 pr-1">
              <b-form-group label="Paciente">
                <b-input v-model="filtros.paciente" />
              </b-form-group>
            </div>
            <div class="col-12 col-sm-3 text-right pt-4">
              <b-btn
                variant="primary"
                size="lg"
                @click="buscar"
                :disabled="loading"
                type="submit"
                ><b-icon-search /> Buscar</b-btn
              >
            </div>
          </div>
        </b-form>
        <BotoesSalvarCancelar
          @salvar="salvar"
          @cancelar="cancelar"
          :okText="'Adicionar selecionado(s)'"
          :okIcon="'fa fa-plus'"
        >
          <template slot="left">
            <div style="min-width:230px">
              <table
                v-if="resumo && resumo.length > 0"
                class="table table-sm table-stripe"
                style="font-size:10px"
              >
                <thead>
                  <tr class="bg-light">
                    <th class="text-left">Tipo</th>
                    <th class="text-center">Qtd.</th>
                    <th class="text-center">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="r in resumo"
                    :key="`lin_res_op_${r.tipo}_${Math.random(12121)}`"
                  >
                    <td class="text-left">{{ r.tipo }}</td>
                    <td class="text-center">{{ r.qtd }}</td>
                    <td class="text-center">{{ r.valor | currency }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="bg-light">
                    <th class="text-left">Total</th>
                    <th class="text-center">
                      {{
                        resumo.reduce((ret, vl) => {
                          ret += vl.qtd;
                          return ret;
                        }, 0)
                      }}
                    </th>
                    <th class="text-center">
                      {{
                        resumo.reduce((ret, vl) => {
                          ret += vl.valor;
                          return ret;
                        }, 0) | currency
                      }}
                    </th>
                  </tr>
                </tfoot>
              </table>
              <div
                class="text-left"
                v-if="selecionados && selecionados.length > 0"
              >
                Selecionados: {{ selecionados.length }}
              </div>
            </div>
          </template>
        </BotoesSalvarCancelar>

        <div class="text-center p-5" v-if="loading">
          <b-spinner /><br />
          aguarde...
        </div>
        <b-table
          v-if="!loading"
          :items="resultados"
          small
          striped
          hover
          ref="selectableTable"
          id="selectableTable"
          selectable
          @row-selected="onRowSelected"
          :show-empty="!resultados || resultados.length <= 0"
          empty-text="Nenhum registro encontrado com os filtros informados"
          style="font-size:11px"
          :fields="[
            {
              key: 'sel',
              label: '',
              class: 'text-center'
            },
            {
              key: 'venda_id',
              label: 'Código',
              class: 'text-center',
              sortable: true
            },
            {
              key: 'tipo',
              label: 'Tipo',
              class: 'text-center',
              sortable: true
            },
            {
              key: 'descricao',
              label: 'Exame / Produto / Serviço',
              class: 'text-left',
              sortable: true
            },
            {
              key: 'dt_venda',
              label: 'Dt. Venda',
              class: 'text-center',
              sortable: true
            },
            {
              key: 'clienteNome',
              label: 'Consumidor',
              class: 'text-left',
              sortable: true
            },
            {
              key: 'vl_custo',
              label: 'Valor',
              class: 'text-center',
              sortable: true
            },
            { key: 'opc', label: '', class: 'text-center' }
          ]"
        >
          <template #head(sel)>
            <b-checkbox :value="1" v-model="checkall" />
          </template>
          <template #cell(sel)="row">
            <b-checkbox v-model="selecionados" :value="row.item" />
          </template>
          <template #cell(descricao)="row">
            <div class="">
              {{ row.item.descricao }}
              <br />
              <small>
                {{ row.item.medicoNome }} ({{ row.item.especialidade }})
              </small>
            </div>
          </template>

          <template #cell(clienteNome)="row">
            <div class="">
              {{ row.item.clienteNome }}
              <small v-if="row.item.pacienteNome">
                <br />
                {{ row.item.pacienteNome }}
              </small>
            </div>
          </template>

          <template #cell(vl_custo)="row">
            <div class="text-center dvValor">
              {{ row.item.vl_custo | currency }}
              <b-btn
                variant="info"
                size="sm"
                @click.prevent="editValor(row.item)"
                class="btEdit"
              >
                <b-icon-pen />
              </b-btn>
            </div>
          </template>

          <template #cell(dt_venda)="row">
            <div class="text-center">
              {{ row.item.dt_venda | moment("DD/MM/YYYY") }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <BotoesSalvarCancelar @salvar="salvar" @cancelar="cancelar" />

    <b-modal
      id="modal-edit-valor"
      small
      size="sm"
      hide-footer
      title="Editar valor"
    >
      <b-input-group label="Valor" v-if="itemEdit">
        <money
          class="form-control text-center"
          v-model="itemEdit.vl_custo"
          v-bind="moneyMask"
        ></money>
      </b-input-group>
      <botoes-salvar-cancelar @salvar="saveEdit" @cancelar="cancelEdit" />
    </b-modal>
  </div>
</template>

<script>
import SelectPaciente from "../../common/SelectPaciente.vue";
import moment from "moment";
import ContasPagarLib from "../../../libs/ContasPagarLib";
import BotoesSalvarCancelar from "../../common/BotoesSalvarCancelar.vue";
export default {
  components: { SelectPaciente, BotoesSalvarCancelar },
  props: {
    excludeList: Array,
    entidadeId: Object
  },
  mounted() {},
  data() {
    return {
      tipos: [
        { value: "-1", text: "Todos" },
        { value: "CONSULTA", text: "Consulta" },
        { value: "EXAME", text: "Exame" },
        { value: "ACERTO", text: "Acerto" }
      ],
      filtros: {
        periodo: {
          dt_i: moment().format("YYYY-MM-DD"),
          dt_f: moment().format("YYYY-MM-DD")
        },
        entidadeId: this.entidadeId,
        tipo: "-1",
        cliente: "",
        paciente: ""
      },
      resultados: [],
      loading: false,
      selecionados: [],
      checkall: 0,
      resumo: [],
      itemEdit: null
    };
  },
  watch: {
    checkall: function() {
      if (this.checkall == 1) {
        this.selecionados = this.resultados;
      } else {
        this.selecionados = [];
      }
    }
  },
  computed: {},
  methods: {
    cancelEdit() {
      this.$bvModal.hide("modal-edit-valor");
      this.itemEdit = null;
    },
    saveEdit() {
      if (this.itemEdit) {
        this.resultados.find(
          x => x.venda_item_id == this.itemEdit.id
        ).vl_custo = this.itemEdit.vl_custo;
        this.cancelEdit();
        this.calculaResumo();
      }
    },
    editValor(item) {
      console.log(item);
      this.itemEdit = {
        vl_custo: item.vl_custo,
        id: item.venda_item_id
      };
      this.$bvModal.show("modal-edit-valor");
    },
    onRowSelected(row) {
      if (row && row.length > 0) {
        let id = row[0].venda_item_id;
        if (this.selecionados.some(x => x.venda_item_id == id)) {
          this.selecionados = this.selecionados.filter(
            x => x.venda_item_id != id
          );
        } else {
          this.selecionados.push(
            this.resultados.find(x => x.venda_item_id == id)
          );
        }
      }
      //   console.log(111111, this.selecionados);
      this.$refs.selectableTable.clearSelected();
    },
    selecionarPacienteFiltro(pac) {
      this.filtros.entidadeId = null;
      if (pac && pac.id) {
        this.filtros.entidadeId = pac.id;
      }
    },
    async buscar() {
      this.loading = true;
      this.resultados = [];
      this.resumo = [];
      try {
        let objBnusca = {
          periodo: this.filtros.periodo,
          entidade_id:
            this.filtros.entidadeId &&
            typeof this.filtros.entidadeId === "object"
              ? this.filtros.entidadeId.id
              : this.filtros.entidadeId,
          tipo: this.filtros.tipo,
          semFatura: true,
          paciente: this.filtros.paciente,
          cliente: this.filtros.cliente
        };
        // console.log(objBnusca);
        let res = await ContasPagarLib.getVendas(objBnusca);

        // console.log("result", res, this.excludeList);
        if (res && res.success) {
          if (this.excludeList && this.excludeList.length > 0) {
            res.data = res.data.filter(
              x =>
                !this.excludeList.some(a => a.venda_item_id == x.venda_item_id)
            );
          }
          this.resultados = res.data;
          this.calculaResumo();
          console.log(this.resumo);
        }
      } catch (err) {
        console.log("erro ", err);
      }
      this.loading = false;
    },
    calculaResumo() {
      this.resumo = this.resultados.reduce((ret, vl) => {
            let has = ret.find(x => x.tipo == vl.tipo);
            if (!has) {
              ret.push({
                tipo: vl.tipo,
                qtd: 1,
                valor: vl.vl_custo
              });
            } else {
              has.qtd++;
              has.valor += vl.vl_custo;
            }
            return ret;
          }, []);
    },
    cancelar() {
      this.$emit("close");
    },
    async salvar() {
      if (!this.selecionados || this.selecionados.length <= 0) {
        this.$swal(
          "Atenção",
          "Selecione ao menos 1 item para adicionar!",
          "error"
        );
        return;
      }
      await this.$emit("salvar", this.selecionados);
      await this.$nextTick();
      setTimeout(() => {
        this.selecionados = [];
        this.buscar();
      }, 220);
    }
  }
};
</script>

<style lang="scss" scoped>
.dvValor {
  .btEdit {
    cursor: pointer;
    visibility: hidden;
  }
}
.dvValor:hover {
  .btEdit {
    visibility: visible;
  }
}
</style>