import { render, staticRenderFns } from "./ContasPagarFaturaForm.vue?vue&type=template&id=137609f8&scoped=true&"
import script from "./ContasPagarFaturaForm.vue?vue&type=script&lang=js&"
export * from "./ContasPagarFaturaForm.vue?vue&type=script&lang=js&"
import style0 from "./ContasPagarFaturaForm.vue?vue&type=style&index=0&id=137609f8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137609f8",
  null
  
)

export default component.exports