<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-sm-3">
        <b-form-group label="Código">
          <b-input disabled v-model="obj.id" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-4">
        <b-form-group label="Pagamento">
          <b-input type="date" v-model="obj.dt_vencimento" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-5 pt-4 mt-1">
        <b-card style="font-size: 10px" body-class="p-2 ">
          <b>Data Criação</b>
          <small>{{ obj.created_at | moment("DD/MM/YYYY HH:mm") }}</small>
          <b class="ml-2">Usuário</b> <small>{{ obj.usuario }}</small>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-8 pr-0">
        <b-form-group label="Cliente / Pagante">
          <select-paciente
            @paciente="selecionarPacienteObj"
            showFidelizacoes
            setNullOnEmpty
            :selecionado="entidade_id"
            :showBotaoAdd="false"
            :showBotaoEdit="false"
            :tipo="['Medico','Laboratorio']"
          />
        </b-form-group>
        <b-form-group
          label="Forma de pagamento"
          v-if="
            obj &&
            obj.entidade_id &&
            obj.entidade_id.configuracoes &&
            obj.entidade_id.configuracoes.contasBancarias
          "
        >
          <b-select
            :options="obj.entidade_id.configuracoes.contasBancarias"
            text-field="nomeExibicao"
            value-field="nomeExibicao"
            v-model="obj.forma_pagamento"
            style="max-width: 85%"
          />
        </b-form-group>
        <b-form-group label="Referente á:" class="pr-4">
          <b-input v-model="obj.referente" />
        </b-form-group>
        <b-form-group label="Observações" class="pr-4">
          <b-textarea v-model="obj.obs" style="height: 51px" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-4 pl-0">
        <b-tabs content-class="card card-body">
          <b-tab>
            <template #title><b-icon-check-all /> Baixa</template>
            <contas-pagar-fatura-baixa :showTitle="false" :faturaId="obj.id" />
          </b-tab>

          <b-tab>
            <template #title><b-icon-globe /> Integração</template>
            <hr class="mb-1" />
            <div class="text-danger mb-2 text-center" v-if="!obj.idExterno">
              <small><b-icon-x-circle-fill /> NÃO INTEGRADO</small>
              <b-btn
                size="sm"
                variant="success"
                block
                :disabled="enviandoERP"
                @click="enviarERP"
              >
                <span v-if="enviandoERP">
                  <b-spinner></b-spinner> aguarde...
                </span>
                <span v-if="!enviandoERP"> <b-icon-hdd-network /> Enviar </span>
              </b-btn>
            </div>
            <div v-else class="text-success">
              <b-icon-check-circle /> Integração OK ({{ obj.idExterno }})
              <hr class="mt-1 mb-1" />
              <small>
                Integrado ás
                {{ obj.export_date | moment("DD/MM/YYYY HH:mm") }} por
                {{ obj.usuarioExport }}
              </small>
              <hr class="mt-1 mb-1" />

              <b-btn
                size="sm"
                variant="success"
                class="mt-3"
                :disabled="enviandoERP"
                block
                @click="enviarERP"
              >
                <span v-if="enviandoERP">
                  <b-progress></b-progress> aguarde...
                </span>
                <span v-if="!enviandoERP">
                  <b-icon-hdd-network /> Re-Enviar</span
                >
              </b-btn>
            </div>
          </b-tab>
        </b-tabs>
      </div>
      <div class="col-6"></div>
      <div class="col-6"></div>
      <div class="col-12 col-sm-12"></div>
      <div class="col-12 col-sm-4"></div>
    </div>
    <b-card>
      <h3>
        <b-icon-list-ol /> Itens da Fatura
        <b-btn variant="info" class="float-right" @click="openAddItem" size="lg"
          ><b-icon-plus-circle /> Adicionar</b-btn
        >
      </h3>
      <b-card-body body-class="p-2">
        <div class="row">
          <div class="col-12 mt-3 mb-3">
            <b-table
              :items="obj.itens"
              :show-empty="obj.itens.length <= 0"
              empty-text="Nenhum item adicionado ainda."
              :fields="[
                {
                  key: 'venda_id',
                  label: 'Código',
                  class: 'text-center',
                  sortable: true,
                },

                {
                  key: 'tipo',
                  label: 'Tipo',
                  class: 'text-center',
                  sortable: true,
                },
                {
                  key: 'descricao',
                  label: 'Exame / Produto / Serviço',
                  class: 'text-left',
                  sortable: true,
                },
                {
                  key: 'dt_venda',
                  label: 'Dt. Venda',
                  class: 'text-center',
                  sortable: true,
                },
                {
                  key: 'clienteNome',
                  label: 'Consumidor',
                  class: 'text-left',
                  sortable: true,
                },
                {
                  key: 'vl_total',
                  label: 'Valor',
                  class: 'text-center',
                  sortable: true,
                },
                { key: 'opc', label: '', class: 'text-center' },
              ]"
              style="font-size: 11px"
              hover
              small
              striped
            >
              <template #cell(descricao)="row">
                <div class="">
                  {{ row.item.descricao }}
                  <br />
                  <small>
                    {{ row.item.medicoNome }} ({{ row.item.especialidade }})
                  </small>
                </div>
              </template>

              <template #cell(vl_total)="row">
                <div class="text-center dvValor">
                  {{ row.item.vl_total | currency }}
                  <b-btn
                    variant="info"
                    size="sm"
                    @click.prevent="editValor(row.item)"
                    class="btEdit"
                  >
                    <b-icon-pen />
                  </b-btn>
                </div>
              </template>

              <template #cell(dt_venda)="row">
                <div class="text-center">
                  {{ row.item.dt_venda | moment("DD/MM/YYYY") }}
                </div>
              </template>

              <template #cell(clienteNome)="row">
                <div class="">
                  {{ row.item.clienteNome }}
                  <small v-if="row.item.pacienteNome">
                    <br />
                    {{ row.item.pacienteNome }}
                  </small>
                </div>
              </template>

              <template #cell(opc)="row">
                <div>
                  <b-btn variant="non" @click="exc(row.item)"
                    ><b-icon-trash
                  /></b-btn>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <div class="row">
      <div class="col-12 mt-3">
        <b-card>
          <h4>
            <i class="fa fa-undo mr-2"></i>Descontos Obrigatórios
            <small>
              <small> (Originado de Devolução) </small>
            </small>
          </h4>
          <hr />
          <b-table
            :items="obj.devolucoes"
            :fields="[
              { key: 'id', label: 'Devolução Nº', class: 'text-center' },
              { key: 'venda_id', label: 'Venda Nº', class: 'text-center' },
              { key: 'venda_data', label: 'Dt. Venda', class: 'text-center' },
              { key: 'descricao', label: 'Descrição', class: 'text-left' },
              { key: 'paciente', label: 'Paciente', class: 'text-left' },
              { key: 'vl_custo', label: 'Valor', class: 'text-center' },
            ]"
            hover
            small
            striped
            ref="tblDevolucoes"
          >
            <template #cell(vl_custo)="row">
              {{ row.item.vl_custo | currency }}
            </template>
            <template #cell(venda_data)="row">
              {{ row.item.venda_data | moment("DD/MM/YYYY") }}
            </template>
          </b-table>
        </b-card>
      </div>
      <!---------------TOTAIS----------->
      <div class="col-12 mt-3">
        <b-card>
          <h2><b-icon-cash /> Totais</h2>
          <b-card-body>
            <div class="row text-center">
              <div class="col-12 col-sm-3">
                <h5>Subtotal</h5>
                <money
                  class="form-control text-center"
                  v-model="obj.vl_subtotal"
                  v-bind="moneyMask"
                  disabled="true"
                ></money>
              </div>
              <div class="col-12 col-sm-2 text-danger">
                <h5>Desconto</h5>
                <money
                  class="form-control text-center"
                  v-model="obj.vl_desconto"
                  v-bind="moneyMask"
                ></money>
              </div>
              <div class="col-12 col-sm-2 text-success">
                <h5>Acrescimo</h5>
                <money
                  class="form-control text-center"
                  v-model="obj.vl_acrescimo"
                  v-bind="moneyMask"
                ></money>
              </div>
              <div class="col-12 col-sm-2 text-warning">
                <h5>Devoluções</h5>
                <money
                  class="form-control text-center"
                  v-model="obj.vl_devolucoes"
                  disabled="true"
                  v-bind="moneyMask"
                ></money>
              </div>
              <div class="col-12 col-sm-3">
                <h5>Total</h5>
                <money
                  class="form-control text-center"
                  v-model="obj.vl_total"
                  disabled="true"
                  v-bind="moneyMask"
                ></money>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <b-modal
      id="modal-add-item-rec"
      hide-footer
      title="Adicionar Item"
      no-enforce-focus
      no-close-on-esc
      size="lg"
    >
      <contas-pagar-busca-items
        @salvar="addItems"
        @close="closeAddItem"
        :excludeList="obj.itens"
        :entidade-id="obj.entidade_id"
      />
    </b-modal>
    <botoes-salvar-cancelar
      :saving="salvando"
      @salvar="salvar"
      @cancelar="$emit('close')"
    >
      <template v-slot:left>
        <b-btn
          variant="info"
          @click="$emit('close', { id: obj.id, tipo: 1 })"
          v-if="obj.id"
          ><b-icon-printer /> Imprimir</b-btn
        >
        <b-btn
          variant="info"
          @click="$emit('close', { id: obj.id, tipo: 2 })"
          v-if="obj.id"
        >
          <i class="fas fa-signature"></i> Recibo</b-btn
        >
      </template>
    </botoes-salvar-cancelar>
    <b-modal
      id="modal-edit-valor"
      small
      size="sm"
      hide-footer
      title="Editar valor"
    >
      <b-input-group label="Valor" v-if="itemEdit">
        <money
          class="form-control text-center"
          v-model="itemEdit.vl_total"
          v-bind="moneyMask"
        ></money>
      </b-input-group>
      <botoes-salvar-cancelar @salvar="saveEdit" @cancelar="cancelEdit" />
    </b-modal>
  </div>
</template>

<script>
import SelectPaciente from "../../../common/SelectPaciente.vue";
import BotoesSalvarCancelar from "../../../common/BotoesSalvarCancelar.vue";
import ContasPagarBuscaItems from "../ContasPagarBuscaItems.vue";
import ContasPagarLib from "../../../../libs/ContasPagarLib";
// import EntidadesLib from '../../../../libs/EntidadesLib';
import VendaLib from "../../../../libs/VendaLib";
// import moment from "moment";
export default {
  components: {
    SelectPaciente,
    BotoesSalvarCancelar,
    ContasPagarBuscaItems,
  },
  props: {
    id: Number,
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      obj: {
        id: null,
        entidade_id: null,
        data: null,
        dt_vencimento: null,
        itens: [],
        vl_subtotal: 0,
        vl_desconto: 0,
        vl_devolucoes: 0,
        vl_acrescimo: 0,
        vl_total: 0,
        obs: "",
        itensExc: [],
        forma_pagamento: {},
        devolucoes: [],
      },
      entidade_id: null,
      salvando: false,
      itemEdit: null,
      enviandoERP: false,
    };
  },
  watch: {
    "obj.vl_desconto": function () {
      this.calcularTotal();
    },
    "obj.vl_acrescimo": function () {
      this.calcularTotal();
    },
    id: function () {
      this.carregar();
    },
  },
  computed: {},
  methods: {
    async getDevolucoes() {
      if (this.obj.desconto_itens) {
        this.obj.devolucoes = this.obj.desconto_itens;
      } else {
        this.obj.devolucoes = [];
      }
      if (this.obj.entidade_id && this.obj.entidade_id.razao_social) {
        let res = await VendaLib.getDevolucoes({
          fornecedor: this.obj.entidade_id?.razao_social,
          notInPayment: true,
        });
        console.log("res devolucoes", res);
        if (res && res.success) {
          let devolucoes = [];
          for (let obj of res.data) {
            if (obj.itens && obj.itens.length > 0) {
              obj.itens = obj.itens.filter(
                (x) => x.fornecedor.trim() == this.obj.entidade_id.razao_social
              );
              devolucoes.push(
                ...obj.itens.map((it) => ({
                  venda_item_id: it.id,
                  paciente: it.paciente,
                  paciente_id: it.paciente_id,
                  id: obj.id,
                  vl_custo: it.vl_custo,
                  descricao: it.descricao,
                  venda_id: obj.venda_id,
                  venda_data: obj.venda_data,
                }))
              );
            }
          }
          this.obj.devolucoes.push(...devolucoes);
        }
        console.log("dev final", this.obj.devolucoes);
        this.obj.vl_devolucoes = this.obj.devolucoes.reduce((ret, vl) => {
          ret += vl.vl_custo;
          return ret;
        }, 0);
        this.$forceUpdate();
        this.calcularTotal();
        this.$refs.tblDevolucoes.refresh()
      }
    },
    cancelEdit() {
      this.$bvModal.hide("modal-edit-valor");
      this.itemEdit = null;
    },
    saveEdit() {
      if (this.itemEdit) {
        this.obj.itens.find(
          (x) => x.venda_item_id == this.itemEdit.id
        ).vl_total = this.itemEdit.vl_total;
        this.cancelEdit();
        this.calcularTotal();
      }
    },
    editValor(item) {
      console.log(item);
      this.itemEdit = {
        vl_total: item.vl_total,
        id: item.venda_item_id,
      };
      this.$bvModal.show("modal-edit-valor");
    },
    async carregar() {
      if (this.id) {
        let res = await ContasPagarLib.getFaturas({ id: this.id });
        console.log("res", res);
        if (res && res.success) {
          this.obj = res.data[0];
          if (this.obj.desconto_itens) {
            this.obj.devolucoes = this.obj.desconto_itens;
          }
          this.entidade_id = this.obj.entidade_id;
          console.log(this.obj);
          if (this.obj.forma_pagamento) {
            this.obj.forma_pagamento = this.obj.forma_pagamento.nomeExibicao;
          }
          console.log(this.obj);

          this.calcularTotal();

          this.$forceUpdate();

          setTimeout(() => {
            if (this.obj.desconto_itens) {
              this.obj.devolucoes = this.obj.desconto_itens;
            }
          }, 2000);
          // console.log("ob", this.obj);
        }
      }
    },
    async salvar() {
      let obj = this.obj;
      let err = [];
      if (
        !obj.entidade_id ||
        obj.entidade_id === 0 ||
        obj.entidade_id < 0 ||
        obj.entidade_id === ""
      ) {
        err.push("Informe um Devedor / Pagante");
      }
      if (!obj.dt_vencimento) {
        err.push("Informe um data de vencimento");
      }
      if (!obj.itens || obj.itens.length <= 0) {
        err.push("Informe ao menos 1 item para continuar");
      }

      if (err.length > 0) {
        this.$swal.fire({
          title: "Atenção",
          html: `Os campos abaixo precisam de atenção<hr/>${err.join("<hr/>")}`,
          icon: "error",
        });
      } else {
        this.salvando = true;
        try {
          if (
            obj.forma_pagamento &&
            obj.forma_pagamento != "" &&
            obj.entidade_id &&
            obj.entidade_id.configuracoes &&
            obj.entidade_id.configuracoes.contasBancarias
          ) {
            obj.forma_pagamento =
              obj.entidade_id.configuracoes.contasBancarias.find(
                (x) => x.nomeExibicao == obj.forma_pagamento
              );
          }
          console.log(obj);

          let res = await ContasPagarLib.storeFatura(obj);

          if (res && res.success) {
            this.$swal({
              title: "Concluído",
              html: "Fatura salva com sucesso!",
              icon: "success",
              showCancelButton: true,
              confirmButtonText: "Imprimir",
              cancelButtonText: "Apenas Fechar",
            }).then((r) => {
              this.$emit(
                "close",
                r.isConfirmed ? { id: res.data, tipo: 1 } : null
              );
            });
          } else {
            this.$swal(
              "Erro",
              "Erro ao salvar" + (res && res.message ? res.message : ""),
              "error"
            );
          }
        } catch (err) {
          console.log("ERRO", err);
        }
        this.salvando = false;
      }
    },
    exc(item) {
      if (this.obj.id) {
        if (!this.obj.itensExc) {
          this.obj.itensExc = [];
        }
        this.obj.itensExc.push({ ...item });
      }
      this.obj.itens = this.obj.itens.filter((x) => x != item);
      this.calcularTotal();
    },
    calcularTotal() {
      this.obj.vl_subtotal = this.obj.itens.reduce((ret, vl) => {
        ret += vl.vl_total;
        return ret;
      }, 0);

      this.obj.vl_total =
        (this.obj.vl_subtotal || 0) -
        (this.obj.vl_devolucoes || 0) -
        (this.obj.vl_desconto || 0) +
        (this.obj.vl_acrescimo || 0);
    },
    selecionarPacienteObj(pac) {
      this.obj.entidade_id = null;
      //this.obj.forma_pagamento = null;
      console.log(pac);
      if (pac && pac.id) {
        this.obj.entidade_id = pac;
        if (
          this.obj.entidade_id &&
          this.obj.entidade_id.configuracoes &&
          this.obj.entidade_id.configuracoes.contasBancarias
        ) {
          for (let conta of this.obj.entidade_id.configuracoes
            .contasBancarias) {
            conta.nomeExibicao =
              conta.tipo +
              " | " +
              conta.nome +
              (conta.tipoChave ? " | Tipo: " + conta.tipoChave : "") +
              (conta.agencia ? " | AG: " + conta.agencia : "") +
              (conta.chave ? " | Numero: " + conta.chave : "") +
              (conta.banco ? " | Banco: " + conta.banco : "");
          }
        }
        this.getDevolucoes();
      }
    },
    openAddItem() {
      this.$bvModal.show("modal-add-item-rec");
    },
    addItems(items) {
      console.log(items);
      items.forEach((it) => {
        if (!this.obj.itens.some((x) => x.venda_item_id == it.venda_item_id)) {
          this.obj.itens.push({
            dt_venda: it.dt_venda,
            entidade_id: it.entidade_id,
            venda_id: it.venda_id,
            venda_item_id: it.venda_item_id,
            descricao: it.descricao,
            cliente_id: it.cliente_id,
            especialidade_id: it.especialidade_id,
            tipo: it.tipo,
            valor: it.vl_custo,
            vl_desconto: 0,
            vl_acrescimo: 0,
            vl_total: it.vl_custo,
            obs: "",
            clienteNome: it.clienteNome,
            medicoNome: it.medicoNome,
            especialidade: it.especialidade,
          });
        }
      });
      this.calcularTotal();

      // this.closeAddItem();
    },
    closeAddItem() {
      this.$bvModal.hide("modal-add-item-rec");
    },
    async enviarERP() {
      if (this.obj.id) {
        this.enviandoERP = true;
        setTimeout(async () => {
          this.$nextTick(() => {
            this.$swal({
              title: "Tem certeza?",
              text: "Deseja enviar a fatura par ao ERP?",
              showCancelButton: true,
              cancelButtonText: "Não",
              confirmButtonText: "Sim",
            }).then(async (r) => {
              if (r.isConfirmed) {
                this.enviandoERP = true;

                let res = await ContasPagarLib.integracaoERP(this.obj.id);
                console.log("resposta", res);
                if (res && res.success) {
                  this.$swal(
                    "Concluído",
                    "Integração realizada com sucesso, codigo do contas a pagar: " +
                      res.data,
                    "success"
                  );
                  this.carregar();
                  this.enviandoERP = false;
                } else {
                  this.$swal("Erro", "Erro ao gerar integração", "error");
                }
              }
            });
          }, 300);
        });
      }
      this.enviandoERP = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dvValor {
  .btEdit {
    cursor: pointer;
    visibility: hidden;
  }
}
.dvValor:hover {
  .btEdit {
    visibility: visible;
  }
}
</style>
