<template>
  <div>
    <div class="text-center">
      <b-btn variant="none" @click="imprimir"
        ><b-icon-printer /> Imprimir</b-btn
      >
    </div>
    <div v-if="!printNow" class="text-center p-4">
      <b-spinner /> <br />
      aguarde...
    </div>
    <div id="printDiv">
      <print-component
        :filename="`Fatura ${fatura.entidadeNome}`"
        v-if="fatura"
        :printNow="printNow"
        @done="$emit('done')"
        :show="true"
        :titulo="`Recibo ${fatura.entidadeNome} - ${fatura.data}`"
      >
        <section class="pdf-item">
          <div
            v-for="n in 2"
            :key="`rec_n_${n}`"
            style="border-bottom: 2px dashed #333; padding-bottom:25px"
          >
            <div class="card mt-4">
              <div class="card-body">
                <h5>
                  {{ fatura.entidadeNome }}
                  <small>({{ fatura.itens[0].especialidade }})</small>
                  <div>
                    <small>
                      <b>CPF:</b> {{ fatura.cpf }}
                      </small>
                  </div>
                </h5>
                <div class="row mt-5">
                  <div class="col-4">
                    FRANCA - SP
                    {{ fatura.crm }}
                  </div>
                  <div class="col-4 text-center">
                    <h2><b>RECIBO</b></h2>
                  </div>
                  <div class="col-4 text-right">
                    <h4>{{ fatura.vl_total | currencyWithMask }}</h4>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12">
                    <b>Recebi(emos) de: </b> Social Serviços Ambulatoriais de
                    Saúde
                  </div>
                  <div class="col-12">
                    <b>O valor de: </b>
                    <span style="text-transform: capitalize;">{{
                      fatura.valorExt
                    }}</span>
                  </div>
                  <div class="col-12">
                    <b>Referente á: </b> {{ fatura.referente }}
                  </div>

                  <div class="col-6">
                    <b>Firmo(amos) o presente:</b>
                    <br />
                    <br />
                    <small v-if="fatura.forma_pagamento">
                      <b>Forma de Pagamento:</b> {{ fatura.forma_pagamento.tipo
                      }}<br />
                      <span
                        v-if="
                          fatura.forma_pagamento.banco &&
                            fatura.forma_pagamento.banco != ''
                        "
                      >
                        <b
                          >{{
                            fatura.forma_pagamento.tipo == "PIX"
                              ? "Tipo:"
                              : "Banco:"
                          }}
                        </b>
                        {{ fatura.forma_pagamento.banco }}
                      </span>
                      <span
                        v-if="
                          fatura.forma_pagamento.agencia &&
                            fatura.forma_pagamento.agencia != ''
                        "
                      >
                        <br />
                        <b>Agencia: </b> {{ fatura.forma_pagamento.agencia }}
                      </span>
                      <span
                        v-if="
                          fatura.forma_pagamento.chave &&
                            fatura.forma_pagamento.chave != ''
                        "
                        class="ml-2"
                      >
                        <b
                          >{{
                            fatura.forma_pagamento.tipo == "PIX"
                              ? "Número:"
                              : "Conta " + fatura.forma_pagamento.tipo + ":"
                          }}
                        </b>
                        {{ fatura.forma_pagamento.chave }}
                        {{
                          fatura.forma_pagamento.digito != ""
                            ? " - " + fatura.forma_pagamento.digito
                            : ""
                        }}
                      </span>
                      <span
                        v-if="
                          fatura.forma_pagamento.numero &&
                            fatura.forma_pagamento.numero != ''
                        "
                        class="ml-2"
                      >
                        <b
                          >{{
                            fatura.forma_pagamento.tipo == "PIX"
                              ? "Número:"
                              : "Conta " + fatura.forma_pagamento.tipo + ":"
                          }}
                        </b>
                        {{ fatura.forma_pagamento.numero }}
                        {{
                          fatura.forma_pagamento.digito != ""
                            ? " - " + fatura.forma_pagamento.digito
                            : ""
                        }}
                      </span>
                      <span
                        v-if="
                          fatura.forma_pagamento.tipoChave &&
                            fatura.forma_pagamento.tipoChave != ''
                        "
                      >
                        <b>Tipo Chave: </b>
                        {{ fatura.forma_pagamento.tipoChave }}
                      </span>
                   
                    </small>
                  </div>
                  <div class="col-6 text-right">
                    <b>FRANCA - SP, {{ dataAtual }}</b>
                    <br /><br /><br /><br /><br /><br />
                    <div class="text-center">
                      <hr class="mb-0" />
                      {{ fatura.entidadeNome }}<br />
                      {{ fatura.cpf }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </print-component>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ContasPagarLib from "../../../../libs/ContasPagarLib";
import PrintComponent from "../../../Print/PrintComponent.vue";
import numero from "numero-por-extenso";
import PrinterWindowHelper from "../../../../helpers/PrinterWindowHelper";
export default {
  components: { PrintComponent },
  props: {
    faturaId: Number
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      printNow: false,
      fatura: null,
      dataAtual: moment()
        .format("DD # MMMM # YYYY")
        .split("#")
        .join("de")
    };
  },
  watch: {},
  computed: {},
  methods: {
    imprimir() {
      PrinterWindowHelper(document.getElementById("printDiv").innerHTML);
    },
    async carregar() {
      if (this.faturaId) {
        let res = await ContasPagarLib.getFaturas({ id: this.faturaId });
        if (res && res.success) {
          this.fatura = res.data[0];
          this.fatura.data = moment(this.fatura.dt_vencimento).format(
            "DD/MM/YYYY"
          );

          this.fatura.valorExt = numero.porExtenso(
            this.fatura.vl_total,
            numero.estilo.monetario
          );

          if (
            this.fatura.forma_pagamento &&
            this.fatura.forma_pagamento.banco
          ) {
            let bancoOpts = require("../../../ContasBancarias/bancoOptions");
            let fnd = bancoOpts.bancoOptions.find(
              x => x.value == this.fatura.forma_pagamento.banco
            );
            if (fnd) {
              this.fatura.forma_pagamento.banco = fnd.text;
            }
          }

          this.printNow = true;
          console.log("item", this.fatura);
        }
      }
    },
    setTipoContaIcon(tipo) {
      let icon;
      if (tipo == "Dinheiro") {
        icon = "dollar-sign";
      }
      if (tipo == "Poupança") {
        icon = "piggy-bank";
      }

      if (tipo == "Corrente") {
        icon = "university";
      }

      if (tipo == "PIX") {
        icon = "mobile";
      }

      return icon;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>